import { useMatch } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'
import { createRoot } from 'react-dom/client'

const date = new Date()
const year = date.getFullYear()

import 'katex/dist/katex.css'
import 'prismjs/themes/prism-twilight.css'
import './src/styles/index.css'

const Header = () => {
  const matchHome = useMatch('/')
  return (
    <div className=' h-10 w-full bg-gray-900 text-gray-500 flex justify-evenly items-center'>
      <Link
        to='/bio'
        activeClassName=' text-gray-100'
        className={`hover:underline hover:text-gray-200${matchHome ? ' text-gray-100' : ''}`}
      >
        /bio
      </Link>
      <Link to='/skills' activeClassName=' text-gray-100' className='hover:underline hover:text-gray-200'>
        /skills
      </Link>
      <Link to='/blog' activeClassName=' text-gray-100' className='hover:underline hover:text-gray-200'>
        /blog
      </Link>
      <Link to='/math' activeClassName=' text-gray-100' className='hover:underline hover:text-gray-200'>
        /math
      </Link>
    </div>
  )
}

const Footer = () => <div className='p-1 text-right w-full fixed right-0 bottom-0 italic bg-gray-100'>{year} Stefano De Vuono</div>

export const replaceHydrateFunction = () => (element, container) => {
  const root = createRoot(container)
  root.render(element)
}

export const wrapPageElement = ({ element }) => (
  <div className='flex flex-col min-h-screen'>
    <Header />
    <div className='m-12 lg:mx-auto max-w-screen-lg'>
      {element}
      <Footer />
    </div>
  </div>
)
